import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot, UrlTree } from '@angular/router';

import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { AuthService } from './auth.service';
import { TokenService } from './token.service';

export const canActivateByRole: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  routerState: RouterStateSnapshot,
): Observable<boolean | UrlTree> => {
  const authService = inject(AuthService);
  const isToken = inject(TokenService).loggedIn();
  const router = inject(Router);

  if (isToken) {
    if (!route.data?.permissions && !route.data?.isRootRoleRequired) {
      return of(true);
    }

    return authService.getUserInfo().pipe(
      map(() => {
        if (route.data?.isRootRoleRequired) {
          return authService.hasRootPermission || router.createUrlTree(['/403']);
        }

        const allowed = authService.checkPermissions(route.data.permissions);
        return !!allowed || router.createUrlTree(['/403']);
      }),
    );
  }

  localStorage.removeItem('accessToken');
  localStorage.removeItem('userId');
  const startUrl = sessionStorage.getItem('startUrl');
  sessionStorage.clear();
  sessionStorage.setItem('startUrl', startUrl ?? routerState.url);

  return of(router.createUrlTree(['/auth/login']));
};

export const canActivateMfa: CanActivateFn = (): Observable<boolean | UrlTree> => {
  const authService = inject(AuthService);
  const router = inject(Router);

  if (inject(TokenService).loggedIn()) {
    return of(router.createUrlTree([authService.homeUrl]));
  }

  if (authService.tmpToken || authService.tempLoginData) {
    return of(true);
  }

  return of(router.createUrlTree(['/auth/login']));
};
